/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useState, useRef, useEffect } from 'react';

const IconLoading = ({ className = '', radius = 10, w = 24, h = 24 }) => {
  const [dashOffset, strokeDashoffset] = useState(0);
  const circle = useRef(null);
  let circleInterval;
  let percent = 0;

  const circleUpdate = () => {
    // let circle = document.querySelector('circle');
    let circumference = radius * 2 * Math.PI;
    const offset = circumference - (percent / 100) * circumference;
    circle.current.style.strokeDashoffset = offset;
    strokeDashoffset(offset);
    percent = (percent + 1) % 100;
  };

  useEffect(() => {
    let circumference = radius * 2 * Math.PI;
    circle.current.style.strokeDasharray = `${circumference} ${circumference}`;
    circle.current.style.strokeDashoffset = `${circumference}`;
    circleInterval = setInterval(circleUpdate, 10);
    return () => clearInterval(circleInterval);
  }, [radius, circleInterval, circle]);

  return (
    <svg className="icn-progress-ring" width={w} height={h}>
      <circle
        ref={circle}
        className="icn-progress-ring__circle"
        stroke="black"
        strokeWidth="0.5625"
        fill="transparent"
        strokeDashoffset={dashOffset}
        r={radius}
        cx={w / 2}
        cy={h / 2}
      />
    </svg>
  );
};

export default IconLoading;
