import React from 'react';
import classnames from 'classnames';
import ImageUrl from 'components/base/ImageUrl';

const Tooltip = ({ data, className = '', style }) => {
  // Eventually, the `sideways` value in the classnames call
  // will have an actual check for whether to display sideways
  // probably based on the image width
  const displayImage = data.images.images
    ? data.images.images[0]
    : data.images['360_images']
    ? data.images['360_images'][0]
    : null;
  const sideways = false;
  return (
    <div
      className={classnames(
        `Tooltip container--boxed absolute ba pa3 body-copy flex ${className}`,
        {
          'flex-column': !sideways,
          'Tooltip--sideways': sideways
        }
      )}
      style={style}
    >
      {displayImage && (
        <div
          className={classnames('Tooltip__image-container', {
            pb3: !sideways,
            pr3: sideways
          })}
        >
          <ImageUrl
            src={displayImage}
            className="Tooltip__image"
            alt={data.name}
          />
        </div>
      )}
      <h3 className="body-subheader">{data.name}</h3>
      <ul className="Tooltip__list">
        {Object.keys(data.values).map((key, index) => {
          return (
            <li key={index}>
              {key}: {data.values[key]}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Tooltip;
