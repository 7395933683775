import React, { useState, useEffect } from 'react';
import Layout from 'components/Layout';
import Link from 'components/base/Link';
import CollectionSearch from 'components/modules/CollectionSearch';
import IconSearch from 'svg/icn-search';
import IconClose from 'svg/icn-close';

const Search = () => {
  const [closeNavTo, setCloseNavTo] = useState('/collection');
  const [fromTaxon, setFromTaxon] = useState(false);
  useEffect(() => {
    setCloseNavTo(
      window.history.state && window.history.state.fromTaxon
        ? `/${window.history.state.fromTaxon}`
        : '/collection'
    );
    setFromTaxon(
      window.history.state && window.history.state.fromTaxon
        ? window.history.state.fromTaxon
        : false
    );
  }, []);
  return (
    <Layout pageTitle="Catalog">
      <article className="CollectionTabs container container--thin container--boxed body-copy-only CollectionTabs--search-active">
        <ul className="block flex">
          <Link
            className="CollectionTabs__search w-100 w-50-md br pointer tc pv2 relative body-subheader"
            to={closeNavTo}
            shouldAnimate={false}
          >
            <IconClose className="CollectionTabs__close-search absolute" />
            <IconSearch className="CollectionTabs__search-title" />
          </Link>
          <Link
            className="CollectionTabs__index w-100 w-50-md pointer tc pv2 relative body-subheader"
            to="/collection/indexed"
            shouldAnimate={false}
            fromTaxon={fromTaxon}
          >
            <span className="CollectionTabs__index-title">Index</span>
          </Link>
        </ul>
      </article>
      {/* The search + search results */}
      <CollectionSearch className="CollectionSearch mb7" data={null} />
    </Layout>
  );
};

export default Search;
