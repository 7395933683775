import React, { Component } from 'react';
import Link from 'components/base/Link';
import Tooltip from 'components/modules/Tooltip';
import { slug } from 'components/utils/helpers';
import classnames from 'classnames';
import IconArrowRight from 'svg/icn-arrow-right';
import IconPlay from 'svg/icn-play';
import IconLoading from 'svg/icn-loading';
import IconClose from 'svg/icn-close';

const esEndpoint =
  'https://oy5fxv675k.execute-api.us-east-2.amazonaws.com/default/mal-endpoint';

class CollectionSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortbyItem: { active: 'name' },
      sortAscending: true,
      searchTerm: '',
      searchResults: [],
      noSearchResults: false,
      isLoading: false,
      freshSearch: false,
      resultsIndex: 25,
      showTooltip: new Array(1000).fill(false),
      tooltipClassName: '',
      tooltipLeft: 0
    };

    this.CollectionSearchWrapper = React.createRef();
    this.SearchInput = React.createRef();
    this.SortbyParent = React.createRef();
    this.SortbyCategory = React.createRef();
    this.SortbyName = React.createRef();
    this.SortbyDecade = React.createRef();

    this.onSearchChange = this.onSearchChange.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onSearchCancel = this.onSearchCancel.bind(this);
    this.doSearch = this.doSearch.bind(this);
    this.onSortClick = this.onSortClick.bind(this);
    this.sortItemsBy = this.sortItemsBy.bind(this);
    this.onLinkEnter = this.onLinkEnter.bind(this);
    this.onLinkLeave = this.onLinkLeave.bind(this);
    this.onpopstate = this.onpopstate.bind(this);
  }

  componentDidMount() {
    this.SearchInput.current.focus();
    const searchString = window.location.search.substring(1);
    window.addEventListener('popstate', this.onpopstate);
    if (searchString.length) {
      const searchTermHumanized = decodeURI(searchString);
      this.setState({
        searchTerm: searchTermHumanized
      });
      this.doSearch(searchTermHumanized);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.onpopstate);
  }

  onpopstate() {
    const searchString = window.location.search.substring(1);
    if (searchString.length) {
      const searchTermHumanized = decodeURI(searchString);
      this.setState({
        searchTerm: searchTermHumanized,
        isLoading: true
      });
      this.doSearch(searchTermHumanized);
    } else {
      this.onSearchCancel();
      this.setState({
        searchResults: []
      });
    }
  }

  onSearchChange(e) {
    this.setState({
      searchTerm: e.target.value,
      freshSearch: false,
      noSearchResults: false
    });
  }

  onSearch(e) {
    e.preventDefault();
    const { searchTerm } = this.state;
    if (searchTerm !== '') {
      let searchTermUri = encodeURI(searchTerm.trim());
      this.doSearch(searchTerm);
      let url = window.location.href.split('?')[0];
      window.history.replaceState(
        {},
        document.title,
        `${url}${url.endsWith('/') ? '' : '/'}?${searchTermUri}`
      );
    } else {
      this.onSearchCancel();
    }
  }

  onSearchCancel() {
    this.SearchInput.current.value = '';
    this.setState({
      searchTerm: '',
      noSearchResults: false,
      searchResults: [],
      freshSearch: false
    });
    this.SearchInput.current.focus();
    const url = window.location.href.split('?')[0];
    window.history.replaceState({}, document.title, url);
  }

  doSearch(searchTerm) {
    this.setState({
      isLoading: true,
      noSearchResults: false,
      searchResults: []
    });
    let items = [];
    fetch(esEndpoint, {
      method: 'POST',
      body: JSON.stringify({
        query: {
          query_string: {
            query: searchTerm,
            default_operator: 'AND'
          }
        }
      })
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        data.forEach(item => {
          items.push(item._source);
        });
        this.setState({
          noSearchResults: items.length === 0,
          isLoading: false,
          freshSearch: true,
          searchResults: this.sortItemsBy(items, 'name')
        });
      })
      .catch(error => {
        console.warn(error);
      });
  }

  onSortClick(e, ref, sortbyThing) {
    // If we're not already sorting by this
    const { sortbyItem, sortAscending, searchResults } = this.state;

    if (sortbyThing !== sortbyItem.active) {
      let sortitAscending = sortAscending;
      this.setState({
        searchResults: this.sortItemsBy(
          searchResults,
          sortbyThing,
          sortitAscending
        ).slice(),
        sortbyItem: { active: sortbyThing }
      });
    } else {
      // we want to flip ascending / descending
      let sortitAscending = !sortAscending;
      this.setState({
        searchResults: this.sortItemsBy(
          searchResults,
          sortbyThing,
          sortitAscending
        ).slice(),
        sortAscending: !sortAscending
      });
    }
  }

  sortItemsBy(items, sortField, ascending = true) {
    // return searchResults as sorted by `sortField`
    let sortedResults = [];
    if (sortField === 'name') {
      sortedResults = items.sort((a, b) => {
        if (ascending) {
          return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
        } else {
          return a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1;
        }
      });
    } else if (sortField === 'category') {
      sortedResults = items.sort((a, b) => {
        if (ascending) {
          return a.values.Medium.toLowerCase() > b.values.Medium.toLowerCase()
            ? 1
            : -1;
        } else {
          return a.values.Medium.toLowerCase() < b.values.Medium.toLowerCase()
            ? 1
            : -1;
        }
      });
    } else {
      // Sort the results by year
      sortedResults = items.sort((a, b) => {
        a.values.Year = a.values.Year || 'Unknown';
        b.values.Year = b.values.Year || 'Unknown';
        if (ascending) {
          return a.values.Year.toLowerCase() > b.values.Year.toLowerCase()
            ? 1
            : -1;
        } else {
          return a.values.Year.toLowerCase() < b.values.Year.toLowerCase()
            ? 1
            : -1;
        }
      });
      // Put all the unknown dates at the end of the list
      sortedResults = sortedResults.sort((a, b) => {
        return a.values.Year === 'Unknown' ? 1 : -1;
      });
    }
    return sortedResults;
  }

  onLinkEnter(e, index) {
    // Show the tooltip
    let arr = new Array(1000).fill(false);
    arr[index] = true;

    this.setState({
      showTooltip: arr
    });
    // Set the tooltip left position
    // and classnames (for quadrant)
    let left =
      e.clientX -
      this.CollectionSearchWrapper.current.getBoundingClientRect().left -
      40;
    this.setState({
      tooltipLeft: left
    });
    let tooltipPosition = 'Tooltip--';
    if (e.clientY > window.innerHeight / 2) {
      tooltipPosition += 'bottom-';
    } else {
      tooltipPosition += 'top-';
    }
    if (e.clientX > window.innerWidth / 2) {
      tooltipPosition += 'right';
    } else {
      tooltipPosition += 'left';
    }
    this.setState({
      tooltipClassName: tooltipPosition
    });
  }

  onLinkLeave() {
    this.setState({
      showTooltip: new Array(100).fill(false)
    });
  }

  render() {
    const { className } = this.props;
    const {
      isLoading,
      searchResults,
      searchTerm,
      freshSearch,
      sortAscending,
      resultsIndex,
      sortbyItem,
      noSearchResults,
      showTooltip,
      tooltipLeft,
      tooltipClassName
    } = this.state;
    return (
      <article
        ref={this.CollectionSearchWrapper}
        className={`${className} container container--thin container--boxed`}
      >
        <section className="ph3 ph4-xl pv4 pv6-xl">
          <form className="searchform flex relative w-90 mha">
            <input
              type="search"
              id="CollectionSearch"
              placeholder="Search"
              className="searchform__input w-100 bb body-header"
              value={searchTerm}
              onChange={this.onSearchChange}
              ref={this.SearchInput}
            />
            <button
              className={classnames('searchform__button absolute', {
                dn: freshSearch
              })}
              onClick={this.onSearch}
            >
              {isLoading ? <IconLoading /> : <IconArrowRight />}
            </button>
            <button
              className={classnames('searchform__button absolute dn', {
                db: freshSearch
              })}
              onClick={e => {
                e.preventDefault();
                this.onSearchCancel();
              }}
            >
              <IconClose />
            </button>
          </form>
        </section>
        <section>
          {searchResults.length > 0 && (
            <ul>
              <li
                ref={this.SortbyParent}
                className="Sortby body-subheader ph3 ph4-xl bt flex"
              >
                <button
                  className="w-50 pv2 br pointer Sortby__elem tl body-subheader"
                  ref={this.SortbyName}
                  onClick={e => {
                    this.onSortClick(e, this.SortbyName, 'name');
                  }}
                >
                  Name
                  <IconPlay
                    className={classnames('ml2 icn-sort', {
                      active: sortbyItem.active === 'name',
                      'active--descending': sortAscending === false
                    })}
                  />
                </button>
                <button
                  className="w-25 pl2 pv2 br pointer Sortby__elem tl body-subheader"
                  ref={this.SortbyDecade}
                  onClick={e => {
                    this.onSortClick(e, this.SortbyDecade, 'decade');
                  }}
                >
                  Year
                  <IconPlay
                    className={classnames('ml2 icn-sort', {
                      active: sortbyItem.active === 'decade',
                      'active--descending': sortAscending === false
                    })}
                  />
                </button>
                <button
                  className="w-25 pl2 pv2 pointer Sortby__elem tl body-subheader"
                  ref={this.SortbyCategory}
                  onClick={e => {
                    this.onSortClick(e, this.SortbyCategory, 'category');
                  }}
                >
                  Category
                  <IconPlay
                    className={classnames('ml2 icn-sort', {
                      active: sortbyItem.active === 'category',
                      'active--descending': sortAscending === false
                    })}
                  />
                </button>
              </li>
            </ul>
          )}
          <ul>
            {noSearchResults && (
              <li className="body-subheader ph3 ph4-xl pv2 bt flex relative i">
                No results were found
              </li>
            )}
            {searchResults.slice(0, resultsIndex).map((item, index) => {
              return (
                <li // eslint-disable-line
                  key={index}
                  className="body-subheader ph3 ph4-xl pv2 bt flex relative"
                  onMouseLeave={this.onLinkLeave}
                >
                  <div className="db w-50 pr2">
                    <Link
                      to={`/collection/${item.slug}`}
                      className="gradient-hover bn"
                      onMouseEnter={e => {
                        this.onLinkEnter(e, index);
                      }}
                    >
                      {item.name}
                    </Link>
                  </div>
                  <div className="pl2 w-25">
                    {item.values.Year || 'Unknown'}
                  </div>
                  <div className="pl2 w-25">
                    <Link
                      to={`/collection/${slug(item.values.Medium)}`}
                      className="gradient-hover bn"
                    >
                      {item.values.Medium}
                    </Link>
                  </div>
                  {showTooltip[index] && (
                    <Tooltip
                      data={item}
                      className={tooltipClassName}
                      style={{ left: tooltipLeft }}
                    />
                  )}
                </li>
              );
            })}
            {(isLoading || resultsIndex < searchResults.length) && (
              <li className="tc mv3">
                {isLoading ? (
                  <IconLoading />
                ) : (
                  <>
                    {resultsIndex < searchResults.length && (
                      <button
                        className="body-subheader gradient-hover"
                        onClick={() => {
                          // show more search results...
                          this.setState({
                            resultsIndex: resultsIndex + 25
                          });
                        }}
                      >
                        Load more...
                      </button>
                    )}
                  </>
                )}
              </li>
            )}
          </ul>
        </section>
      </article>
    );
  }
}

export default CollectionSearch;
