import React from 'react';

const IconArrowRight = ({ className = '' }) => {
  return (
    <svg className={className} width="23px" height="23px" viewBox="0 0 23 23">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-1091.000000, -358.000000)"
          stroke="#000000"
          strokeWidth="0.5625"
        >
          <g transform="translate(1091.250000, 358.875000)">
            <line
              x1="0.102115385"
              y1="22.0389231"
              x2="22.6021154"
              y2="12.0235385"
            ></line>
            <line
              x1="0.102115385"
              y1="0.101711538"
              x2="22.6021154"
              y2="10.1170962"
            ></line>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconArrowRight;
